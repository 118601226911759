.superfood_container {
    display: flex;
    flex-direction: row;
    gap: 6vw;
    justify-content: center;
    align-items: center;
    margin: 16vh 0;
}

.muelsi_img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.second_img_muelsi {
    margin-top: 9vh;
    transition: transform 0.2s ease-out;
}

.first_img_muelsi {
    transition: transform 0.2s ease-out;
}

.first_img_muelsi:hover {
    transform: scale(1.1);
}

.third_img_muelsi {
    transition: transform 0.2s ease-out;
}

.third_img_muelsi:hover {
    transform: scale(1.1);
}

.second_img_muelsi:hover {
    transform: scale(1.1);
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8vh;
}

.product_name {
    display: flex;
    flex-direction: row;
    gap: 2vw;
    width: 35%;
    border-radius: 30px;
}

.superfood_muelsi {
    font-size: 55px;
    border-radius: 9px;
    background-color: white;
    padding: 1vh 0;
}

.text_muelsi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 13vh;
}

.muelsi {
    font-size: 40px;
    font-weight: 500;
    background-color: white;
    padding: 1vh 0;
    width: 50%;
}

.text_muelsi h1,
h2 {
    color: black;
}

.text_desc {
    line-height: 2;
    font-size: 20px;
    letter-spacing: 1px;
}

.superfood_samp{
    color: #ffaf01;
}

.samp_no_color{
    font-weight: 900;
}

@media (max-width: 1600px) {
    .superfood_container {
        display: flex;
        flex-direction: column-reverse;
    }

    #first_img_muelsi,
    #second_img_muelsi,
    #third_img_muelsi {
        height: 29vh;
    }

    .multigraine_description {
        margin: 0vh 3vw;
    }

    .description {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .product_name {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .superfood_muelsi {
        font-size: 50px;
        padding: 0 4vw;
        background: white;
        padding: 1vh 2vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .muelsi {
        font-size: 28px;
        font-weight: 500;
        background-color: white;
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
        padding: 2vh 2vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text_muelsi {
        display: flex;
        align-items: center;
    }

    .text_desc {
        width: 80%;
    }
}

@media (max-width: 1300px) {

    #first_img_muelsi,
    #second_img_muelsi,
    #third_img_muelsi {
        height: 28vh;
    }

    .muelsi {
        font-weight: 500;
    }

    .description {
        padding: 0 2vw;
    }

    .superfood_muelsi {
        background-color: white;
        border-radius: 9px;
        width: 100%;
        padding: 2vh 0;
    }

    .muelsi {
        background-color: white;
        border-radius: 9px;
        width: 50%;
        padding-bottom: 1vh;
    }
}

/*------------*/
@media (max-width: 1024px) {
    .superfood_muelsi {
        font-size: 50px;
    }

    .muelsi {
        font-size: 35px;
    }

    .text_desc {
        font-size: 15px;
    }

    #first_img_muelsi,
    #second_img_muelsi,
    #third_img_muelsi {
        height: 25vh;
    }
}

/*------------*/
@media (max-width: 768px) {
    .superfood_container {
        display: flex;
        flex-direction: column-reverse;
    }

    .muelsi_img {
        gap: 2vw;
    }

    #first_img_muelsi,
    #second_img_muelsi,
    #third_img_muelsi {
        height: 25vh;
    }

    .text_muelsi {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .superfood_muelsi {
        font-size: 50px;
        padding: 0 4vw;
        background: white;
        padding: 1vh 2vw;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .muelsi {
        font-size: 28px;
        font-weight: 500;
        background-color: white;
        border-bottom-right-radius: 9px;
        border-bottom-left-radius: 9px;
        padding: 2vh 2vw;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text_desc {
        font-size: 20px;
        padding: 0vh 0vw;
        line-height: 2;
        letter-spacing: 0.4px;
    }
}

@media (max-width:596px) {

    #first_img_muelsi,
    #second_img_muelsi,
    #third_img_muelsi {
        height: 20vh;
    }
}

/*------------*/
@media (min-width:399px) and (max-width: 480px) {

    #first_img_muelsi,
    #second_img_muelsi,
    #third_img_muelsi {
        height: 17vh;
    }
}

/*------------*/
@media (max-width: 390px) {
    .superfood_muelsi {
        font-size: 40px;
    }

    .muelsi {
        font-size: 25px;
    }

    .text_desc {
        font-size: 16px;
        line-height: 2;
        letter-spacing: 0.6px;
    }

    #first_img_muelsi,
    #second_img_muelsi,
    #third_img_muelsi {
        height: 18vh;
    }
}

@media (max-width:350px) {

    #first_img_muelsi,
    #second_img_muelsi,
    #third_img_muelsi {
        height: 14vh;
    }
}