@font-face {
    font-family: 'futurali';
    src: url('../components//font/futurali.ttf') format('truetype');
}

* {
    font-family: 'futurali', sans-serif;
}

.about-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.description_stat {
    padding: 4vh 0;
    display: flex;
    flex-direction: column;
    background: url('./products/image/our-story-bg.svg') no-repeat center center;
    background-size: cover;
}

.our_story {
    display: flex;
    flex-direction: column;
    gap: 3vw;
}

.section_title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.all_description {
    display: flex;
    flex-direction: row;
    padding: 0 4vw;
    padding-top: 7vh;
    gap: 8vw;
}

.about_us_stat {
    border-radius: 20px;
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3vh;
    padding-bottom: 4vh;
    gap: 2vh;
}

.image_section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 88%;
    background: linear-gradient(rgba(255, 175, 1, 0.342), rgba(255, 175, 1, 0.329)),
        url('../assets/images/grains2.svg') no-repeat center center;
    background-size: cover;
    border-radius: 8px;
}

.jolien {
    height: 50vh;
    padding-top: 3vh;
}

.image_svg {
    display: flex;
    align-items: center;
}

.team {
    display: flex;
    flex-direction: column;
    gap: 3vh;
    background-color: #ffaf01;
    justify-content: center;
    padding: 10px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.container_team {
    display: flex;
    color: whitesmoke;
    align-items: center;
    gap: 1vw;
}

.container_team i {
    font-size: 28px;
}

.description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 88%;
    gap: 3vh;
}

.description h1 {
    color: black;
}

.button_product {
    background-color: #0892e7;
    color: black;
    border: none;
    border-radius: 50px;
    height: 6vh;
    width: 11vw;
    box-shadow: rgb(0, 0, 0) 15px 15px 0px;
    font-size: 20px;
    font-weight: 900;
}

.who_we_are {
    display: flex;
    flex-direction: column;
    gap: 4vh;
    width: 60%;
}

.who_we_are p {
    font-size: large;
}

.start {
    padding-bottom: 2vh;
    font-size: x-large;
}

.words {
    font-size: 35px;
    line-height: 1.8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2vh;
}

.oh_my_samp {
    padding-right: 0.4vw;
    font-weight: bold;
    color: #ffaf01;
}

.start_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
    margin-top: 8vh;
}

.jolien_samp {
    font-size: 22px;
    color: #ffaf01;
}

.our_story_hr {
    border: none;
    width: 7vw;
    height: 1vh;
    background-color: #ffaf01;
    border-radius: 10px;
}

.grain {
    font-weight: bold;
    font-size: large;
}

.picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background:
        url("../assets/images/wave-bottom.svg") no-repeat center center / cover;
    background-size: cover;
}

.hr_about {
    padding: 5vh 0;
    display: flex;
    height: 9vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.picture_hr {
    border: none;
    width: 7vw;
    height: 1vh;
    background-color: #ffaf01;
    border-radius: 10px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.247);
}

.buttons_and_description {
    line-height: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: 6vh;
    width: 37%;
    margin-top: 12vh;
}



.all_picture {
    padding-top: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5vw;
}

.store_svg {
    height: 50vh;
    border-radius: 12px;
}

.store_div {
    padding: 2vw;
    background-color: #ffaf01;
    border-radius: 10px;
}

.title {
    background-color: #ffaf01;
    padding: 1vh;
    border-radius: 15px;
    font-size: 15px;
    width: 18vw;
}

.kid_svg {
    height: 50vh;
    border-radius: 22px;
}

/* ✅ Grand écran (PC) */
@media (max-width: 1440px) {
    .all_description {
        gap: 6vw;
    }

    .who_we_are {
        width: 65%;
    }

    .words {
        font-size: 30px;
    }
}

/* ✅ Tablette (1024px, 768px) */
@media (max-width: 1024px) {
    .all_description {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 6vw;
    }

    .who_we_are {
        width: 80%;
    }

    .words {
        font-size: 28px;
    }

    .image_section {
        width: 100%;
    }

    .jolien {
        height: 40vh;
    }

    .store_svg,
    .kid_svg {
        width: 100%;
        height: auto;
    }

    .all_picture {
        flex-direction: column;
        gap: 3vh;
    }

}

@media (max-width: 990px) {
    .all_picture {
        padding-top: 0vh;
        gap: 9vh;
    }

    .store_svg {
        width: 60vw;
    }

    .kid_svg {
        width: 60vw;
    }

    .description_stat {
        margin-top: 6vh;
        background:
            url("../assets/images/our-story-topbg-900px.svg") no-repeat center center / cover,
            url("../assets/images/our-story-btbg-900px.svg") no-repeat center center / cover;
        background-size: cover;
    }

    .image_section {
        width: 90%;
    }

    .who_we_are {
        width: 70%;
    }
}


/* ✅ Mobile (600px et moins) */
@media (max-width: 600px) {
    .section_title {
        font-size: 24px;
    }

    .words {
        font-size: 22px;
    }

    .who_we_are {
        width: 100%;
        padding: 0 4vw;
    }

    .start {
        font-size: 18px;
    }

    .about_us_stat {
        width: 90%;
    }

    .jolien {
        height: 30vh;
    }

    .store_div {
        padding: 1.5vw;
    }

    .button_product,
    .button_picture {
        width: 80%;
        height: 6vh;
        font-size: 18px;
    }

    .all_picture {
        padding-top: 5vh;
    }
}