@font-face {
  font-family: 'futurali';
  src: url('./components//font/futurali.ttf') format('truetype');
}

* {
  font-family: 'futurali', sans-serif;
}

#about {
  display: flex;
  align-items: center;
  justify-content: center;
}

#home {
  display: flex;
  flex-direction: column;
  background:
    url('./assets/images/home-bg.svg')no-repeat center center / cover;
  background-size: cover;
}

#about {
  background:
    url('./assets/images/wave-haikei-top.svg') no-repeat center center / cover;
  background-size: cover;
}

#contact {
  background: url("./assets/images/layered-waves-haikei-contact.svg");
  background-size: cover;
  padding-bottom: 3vh;
  background-color: #ffaf01;
}

.learn_more a {
  color: black;
}


@media (max-width: 1300px) {
  #home {
    background:
      url('./assets//images/Home-bg-1300px.svg') no-repeat center center / cover;
    background-size: cover;
  }
}

@media (max-width:777px) {
  #home {
    background:
      url('./assets//images/home-gb-responsive-300px.svg') no-repeat center center / cover;
    background-size: cover;
  }

  .animate_text {
    gap: 1vh;
  }

  .learn_more {
    height: 6vh;
    width: 36%;
  }
}

@media (max-width: 400px) {
  #home {
    background:
      url('./assets//images/home-bg-200px.svg') no-repeat center center / cover;
    background-size: cover;
  }
}