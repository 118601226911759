@font-face {
    font-family: 'Typold-Book500';
    src: url('../font/Typold-Book500.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


.logo-container {
    font-family: 'Typold-Book500', sans-serif;
}

.nav-links a,
.dropdown-title,
.dropdown-menu a {
    font-family: 'Typold-Book500', sans-serif;
    font-weight: 900;
    font-size: 29px;
    color: white;
    text-decoration: none;
}

.navbar {
    background: rgba(0, 0, 0, 0.041);
    backdrop-filter: blur(10px);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding: 15px 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.138);
}

.navbar-container {
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    justify-content: space-between;
}

.logo {
    height: 80px;
    cursor: pointer;
}


.nav-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.nav-links a {
    color: rgba(0, 0, 0, 0.763);
    text-decoration: none;
    font-size: 19px;
    font-weight: 900;
    padding: 10px 15px;
    transition: color 0.3s ease-in-out;
}


.dropdown {
    position: relative;
}

.dropdown-title {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdown-title i {
    margin-left: 5px;
    transition: transform 0.3s ease;
}


.dropdown-menu {
    position: absolute;
    top: 50px;
    left: 0;
    background: white;
    border-radius: 5px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.229);
    min-width: 200px;
    text-align: left;
}

.dropdown-menu a {
    display: block;
    padding: 10px;
    color: rgba(36, 36, 36, 0.845);
    transition: background 0.3s ease-in-out;
}

.dropdown-menu a:hover {
    background: #ffaf01;
    color: white;
}

.dropdown:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.buttons_class a {
    color: rgba(0, 0, 0, 0.763);
    font-weight: 900;
    height: 6vh;
}

.buttons_class a:hover {
    color: rgba(0, 0, 0, 0.763);
}

.buttons_class {
    border: none;
    border-radius: 7px;
    background: #ffaf01;
    padding: 1.5vh;
}

.social-icons {
    display: flex;
    gap: 15px;
}

.icon_media {
    color: white;
    font-size: 28px;
    transition: transform 0.3s ease-in-out;
}

.icon_media:hover {
    transform: scale(1.2);
    color: #ffaf01;
}

.icon_media i {
    color: rgba(0, 0, 0, 0.763);
}

@media (max-width:990px) {
    .navbar{
        display: none;
    }
}