* {
    text-decoration: none;
    margin: 0;
    padding: 0;
}

.all_products {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.barbecue {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background:
        url("../products/image/barbecue-bg.svg") center bottom/contain no-repeat;
    background-size: cover, contain;
    padding: 15vh 0;
}

.granola_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background:
        url("./image/granola-bg.svg") center center / cover no-repeat;
    background-size: cover;
    padding: 10vh 0;
}

.crackers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background:
        url("./image/img-granola-bg.svg") center center / cover no-repeat;
    background-size: cover;
    padding: 6vh 0;
}

.superfood {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background:
        url("./image/img-granola-bg.svg")center center / cover no-repeat;
    background-size: cover;
    padding: 9vh 0;
}

@media (max-width: 1300px) {
    .barbecue {
        padding: 0;
        background:
            url("./image/lentil_crips-1300px.svg")center center / cover no-repeat;
        background-size: cover;
    }
}

@media (max-width: 1600px) {
    .granola_bar {
        background:
            url("./image/granola-bg-1500px.svg")center center / cover no-repeat,
            url("./image/graines.png")center center / cover no-repeat;
        background-size: cover;
    }
}

@media (max-width:990px) {
    .granola_bar {
        padding-top: 18vh;
        background:
            url("./image/granola-bar-bg-top.svg")center center / cover no-repeat,
            url("./image/graines.png")center center / cover no-repeat;
        background-size: cover;
    }

    .barbecue {
        background:
            url("./image/barbecue-bg-370px.svg")center center / cover no-repeat;
        background-size: cover;
    }
}

@media (min-width:660px) and (max-width:988px) {
    .barbecue {
        background:
            url("./image/granola-bg-975px.svg")center center / cover no-repeat;
        background-size: cover;
    }
}

@media (max-width: 660px) {
    .barbecue {
        background:
            url("./image/granola-bg-975px.svg")center center / cover no-repeat;
        background-size: cover;
    }
}