* {
    text-decoration: none;
    margin: 0;
    padding: 0;
}

.barbecue_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7vw;
    margin: 10vh 0;
}

.barbecue_img {
    height: 68vh;
    width: auto;
    transition: transform 0.3s ease;
}

.barbecue_img:hover {
    transform: scale(1.1);
}

.name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3vh;
}

.lentil {
    font-size: 40px;
    color: black;
    margin-left: 0.6vw;
}

.crisps {
    font-size: 85px;
    font-weight: 900;
    color: black;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4vh;
    width: 45%;
}

.text_info {
    font-size: 21px;
    line-height: 2;
}

.lentil_samp{
    font-weight: bold;
}

.healthy_food {
    height: 7vh;
    width: 12vw;
    font-weight: 400;
    background-color: #ffaf01;
    border: none;
    border-radius: 7px;
    box-shadow: 4px 5px 100px 12px rgba(29, 29, 29, 0.25), 36px 24px 90px 7px rgba(56, 44, 2, 0.34);
}

.containt_product_div {
    display: flex;
    flex-direction: column;
    gap: 3vh;
    justify-content: center;
    align-items: center;
}

.containt_product {
    background-color: rgb(141, 29, 29);
    height: 8vh;
    width: 8vw;
    border-radius: 8px;
}

.containt_product_palm {
    height: 5vh;
    width: 3vw;
    border-radius: 5px;
}

.flavour {
    height: 25vh;
    width: auto;
}

.text_flavour {
    height: 4vh;
}

.img_cheese {
    display: flex;
    flex-direction: row;
    gap: 4vw;
}

.img_cheese img {
    height: 40vh;
}

.cheese_lentils {
    margin-top: 4vh;
    transition: transform 0.3s ease;
}

.cheese_lentils:hover {
    transform: scale(1.1);
}

@media (max-width: 1500px) {
    .name_title{
        display: flex;
        flex-direction: column;
        gap: 3vh;
        justify-content: center;
    }
    .lentil{
        font-size: 35px;
        font-weight: 500;
        margin-left: 0.8vw;
    }
    .crisps {
        font-size: 70px;
        font-weight: 900;
    }

    .text_info {
        font-size: 20px;
        line-height: 2;
        letter-spacing: 1px;
    }

    #barbecue_img,
    #cheese_lentils {
        height: 32vh;
    }
}

@media (max-width: 1500px) {
    .barbecue_container {
        display: flex;
        flex-direction: column;
        margin-bottom: 3vh;
    }

    .text {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2vh;
        padding: 0 4vw;
        margin-bottom: 2vh;
    }

    .name_title {
        display: flex;
        flex-direction: column;
        gap: 4vh;
        justify-content: center;
        align-items: center;
    }
}

/*------------*/
@media (max-width: 1024px) {
    .lentil{
      font-size: 35px;
    }

    .crisps{
        font-size: 50px;
    }

    .text_info{
        font-size: 15px;
        line-height: 2;
    }
}

/*------------*/
@media (max-width: 768px) {
    .barbecue_container {
        display: flex;
        flex-direction: column;
        margin-bottom: 3vh;
    }

    .text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2vh;
        padding: 0 4vw;
        margin-bottom: 2vh;
    }

    .name_title {
        display: flex;
        flex-direction: column;
        gap: 2vh;
        justify-content: center;
        align-items: center;
    }

    .lentil {
        font-size: 28px;
    }

    .crisps {
        font-size: 55px;
        padding: 0 4vw;
    }

    .text_info {
        font-size: 20px;
        padding: 2vh 3vw;
        line-height: 2;
        letter-spacing: 0.4px;
    }

    .img_cheese {
        gap: 8vw;
    }

    #barbecue_img {
        height: 26vh;
    }

    #cheese_lentils {
        height: 26vh;
    }
}

@media (min-width: 479px) and (max-width:498px) {
    .name_title {
        gap: 0vh;
        margin-bottom: 1vh;
    }
}

/*------------*/
@media (max-width: 480px) {
    .granola_bar_container {
        padding-bottom: 17vh;
    }

    .granola_title {
        margin-bottom: 0vh;
    }

    .crisps {
        font-size: 45px;
    }

    .name_title {
        gap: 1vh;
    }

    .name_title {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .lentil {
        font-size: 25px;
    }

    .text_info {
        font-size: 19px;
        line-height: 2;
        letter-spacing: 0.6px;
    }
}

@media (max-width:410px) {
    #barbecue_img {
        height: 21vh;
    }

    #cheese_lentils {
        height: 21vh;
    }
}

/*------------*/

@media (max-width: 380px) {
    .name_title {
        margin-top: 2vh;
    }

    .text_info {
        font-size: 17px;
        line-height: 2;
        letter-spacing: 0.6px;
    }
}