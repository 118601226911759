* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-align: none;
    box-sizing: border-box;
}

.logo {
    height: 6vh;
}

/* Bouton pour ouvrir le menu */
#nav-toggle {
    color: black;
    background: white;
    border-radius: 5px;
    height: 6vh;
    width: 7vw;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}



/* Navbar responsive - cachée par défaut */

.navigation_responsive {
    display: none;
}

.center_icon_div{
    display: flex;
    flex-direction: row;
    gap: 8vw;
}

.media{
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media i {
    font-size: 30px;
}

.navbarresponsive {
    display: none;
    position: fixed;
    top: 3vh;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    z-index: 10000;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
}


.navbarresponsive.active {
    opacity: 1;
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.nav_responsive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.8);
    border-radius: 18px;
    padding: 20px 0;
}

.icon_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3vw;
    margin: 10px 0;
}

.remix_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 0.7vh;
    font-size: large;
    transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}

.remix_icon:hover {
    transform: scale(1.1);
}

.remix_icon i {
    font-size: 22px;
}

/* Icônes sociales */
.social-icons {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.icon_media {
    color: white;
    font-size: 20px;
    transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}

.icon_media:hover {
    transform: scale(1.2);
    color: #f8b400;
}

/* Responsive pour mobiles */
@media (max-width: 990px) {
    .navbarresponsive {
        width: 93%;
    }

    .navigation_responsive {
        display: flex;
        flex-direction: row;
        position: fixed;
        top: 6vh;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        z-index: 1000;
        padding: 10px 28px;
        width: 95%;
        justify-content: space-between;
        background: white;
        gap: 2vw;
        border-radius: 10px;
        -webkit-box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.281);
        -moz-box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.322);
        box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.37);
    }
}

@media (max-width: 540px) {
    #nav-toggle{
        padding: 0.6vh 5vw;
    }
}