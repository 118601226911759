* {
    text-decoration: none;
    margin: 0;
    padding: 0;
}

#granola_bar_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4vw;
}

.granola_img {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    padding: 0 10vw;
    background-size: cover;
}

.granola_img_green {
    margin-top: 50%;
    transform: rotate(-11deg);
    transition: transform 0.3s ease;
}

.granola_img_blue {
    transition: transform 0.3s ease-in-out;
}

.granola_img_blue:hover {
    transform: scale(1.1);
}

.granola_img_green:hover {
    transform: scale(1.1);
}

.granola_description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2vh;
    padding-right: 3vw;
}

.granola_title {
    width: 27%;
    border-radius: 30px;
    gap: 0.5vh;
}

.granola {
    font-size: 55px;
    font-weight: 900;
    color: black;
    background-color: white;
    padding: 1vh 0;
    width: 100%;
}

.bar {
    font-size: 30px;
    font-weight: 500;
    color: black;
    width: 45%;
    background-color: white;
    padding: 1vh 0.3vw;
}

.bar_samp{
    color: #ffaf01;
}

.containt {
    line-height: 2;
    font-size: 20px;
    letter-spacing: 0.6px;
}

#containt_text {
    line-height: 2;
    font-size: 20px;
    letter-spacing: 0.6px;
}

.kcla {
    font-size: x-large;
    font-weight: 900;
}

.samp_sugar{
    color: #ffaf01;
    font-weight: 900;
}

@media (max-width: 1600px) {
    #granola_bar_container {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }

    .granola_desciption_div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .granola_description {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6vh;
    }

    .granola_title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3vh;
        padding: 0;
        padding-top: 12vh;
    }

    .granola {
        font-weight: 900;
        font-size: 55px;
        background-color: white;
        border-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bar {
        font-weight: 500;
        font-size: 40px;
        background-color: white;
        border-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #containt_text {
        width: 70vw;
        line-height: 2;
    }

    .kcla {
        font-size: 20px;
    }
}

@media (max-width: 1300px) {

    .granola_img_green,
    .granola_img_blue {
        height: 50vh;
    }

    .granola_title {
        display: flex;
        flex-direction: column;
        gap: 2vh;
        width: 100%;
        padding: 0;
        padding-top: 12vh;
    }

    .granola {
        font-weight: 900;
        font-size: 55px;
        background-color: white;
        border-radius: 9px;
    }

    .bar {
        font-weight: 500;
        font-size: 30px;
        margin-left: 0.8vw;
        background-color: white;
        border-radius: 9px;
    }

    .granola_description {
        padding: 0;
        margin: 0;
        justify-content: start;
    }
}

/*------------*/
@media (max-width: 1024px) {

    #containt_text {
        font-size: 15px;
    }
}

/*------------*/
@media (max-width: 768px) {

    .granola_img_green,
    .granola_img_blue {
        height: 39vh;
    }

    .granola_description {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .granola_title {
        padding-top: 8vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .granola {
        font-weight: 900;
        font-size: 50px;
        background-color: white;
        border-radius: 9px;
        padding: 1vh 3vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bar {
        font-weight: 500;
        font-size: 30px;
        background-color: white;
        border-radius: 9px;
        padding: 1vh 3vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #containt_text {
        font-size: 20px;
        line-height: 2;
        letter-spacing: 0.4px;
    }
}

@media (max-width:594px) {}

/*------------*/
@media (max-width: 480px) {
    #containt_text {
        font-size: 19px;
        line-height: 2;
        letter-spacing: 0.6px;
    }
}

@media (max-width: 380px) {

    .granola {
        font-size: 40px;
    }

    .bar {
        font-size: 25px;
    }

    .kcla {
        font-size: 18px;
    }

    #containt_text {
        font-size: 15px;
    }
}

@media (max-width: 350px) {

    .granola_desciption_div {
        padding: 0;
        margin: 0;
    }

    .granola_description {
        padding: 0;
        margin: 0;
    }

    #containt_text {
        padding: 0;
        margin: 0;
    }
}