@font-face {
    font-family: 'futurali';
    src: url('../components//font/futurali.ttf') format('truetype');
}

* {
    font-family: 'futurali', sans-serif;
}

* {
    font-family: 'Alfa Slab One', cursive;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.home-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    text-align: center;
}

/* Splash Background */
.splash-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.9;
}

/* ✅ Texte toujours en flex */
.animate_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin-bottom: 20vh;
}

.wavy-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    color: #333;
    flex-wrap: wrap;
}

/* ✅ Pour s'assurer que le texte reste bien affiché */
.wavy-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: #ffaf01;
    animation: wave 4s ease-in-out infinite;
    width: 13vw;
}

/* ✅ Reste en display flex */
.static-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw;
    margin-left: 0.7vw;
    font-size: 2.5rem;
    color: black;
    font-weight: bold;
}

.animate_text p {
    margin-top: 2vh;
    letter-spacing: 0.7px;
    line-height: 1.6;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
}

.learn_more {
    margin-top: 3vh;
    height: 6vh;
    border: none;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 900;
}

.learn_more_button_a{
    height: 6vh;
    width: 12vw;
    padding: 2vh 4vw;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    background-color: #ffaf01;
}

.learn_more:hover {
    transform: scale(1.1);
}

.learn_more:active {
    transform: scale(0.9);
}

/*responsivity */

@media (max-width:1498px) {
    .wavy-text {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.8vw;
    }
}

@media (max-width: 1280px) {
    .animate_text {
        width: 75%;
    }

    .animate_text p {
        font-size: 18px;
    }

    .learn_more {
        width: 18vw;
        font-size: 16px;
    }

    .wavy-container,
    .static-text {
        font-size: 2rem;
    }
}

@media (max-width: 1024px) {
    .animate_text {
        width: 83%;
        gap: 2vh;
    }

    .animate_text p {
        font-size: 17px;
        width: 89%;
        line-height: 1.8;
    }

    .learn_more {
        width: 20vw;
        height: 8vh;
        font-size: 15px;
        font-weight: 500;
    }

    .wavy-container,
    .static-text {
        font-size: 1.7rem;
    }
}

@media (max-width: 990px) {
    .wavy-container {
        flex-direction: column;
        align-items: center;
        gap: 2vh;
    }
}

@media (max-width: 768px) {
    .animate_text {
        width: 90%;
        margin-bottom: 8vh;
    }

    .animate_text p {
        font-size: 14px;
    }

    .learn_more {
        width: 60%;
        height: 6.5vh;
        font-size: 14px;
        font-weight: 500;
    }

    .wavy-container,
    .static-text {
        font-size: 1.4rem;
    }

}

@media (max-width: 480px) {
    .animate_text {
        width: 95%;
        margin-bottom: 5vh;
    }

    .animate_text p {
        font-size: 13px;
        line-height: 1.5;
    }

    .learn_more {
        width: 80%;
        height: 6vh;
        font-size: 13px;
    }

    .wavy-container,
    .static-text {
        font-size: 1.2rem;
    }
}

@media (max-width: 380px) {
    .animate_text p {
        font-size: 13px;
        line-height: 2;
    }

    .learn_more {
        width: 100%;
        height: 40vh;
        font-size: 13px;
    }
}