* {
    text-decoration: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contact_section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12vw;
    padding: 4vw;
}

.localisation {
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.location {
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.map {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1vw;
    font-size: 18.5px;
}

.map i {
    font-size: 40px;
}

.general_condition a{
    cursor: pointer;
    color: black;
    font-size: 20px;
}

.contact {
    display: flex;
    gap: 0.5vw;
}

.contact a {
    color: black;
}

.contact i {
    font-size: 40px;
}

.send_message {
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.power {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    font-size: 18.5px;
}

.input {
    height: 5vh;
    width: 20vw;
    border: 2px solid rgba(0, 0, 0, 0.61);
    border-radius: 30px;
}

.name {
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.name label {
    font-size: 24px;
    font-weight: 800;
}

.contact_form_input {
    border: 2px solid rgba(0, 0, 0, 0.61);
    border-radius: 30px;
    height: 10vh;
    width: 20vw;
}

.submit {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;
    border: none;
    border-radius: 50px;
    height: 6vh;
    width: 11vw;
    box-shadow: rgb(0, 0, 0) 15px 15px 0px;
    font-size: 23px;
    font-weight: 900;
    gap: 1vw;
    padding: 1.5vw;
    transition: transform 0.2s ease-in-out;
    margin-top: 2vh;
}

.submit:hover {
    transform: scale(1.1);
}

.submit:active {
    transform: scale(0.9);
}

/* Réactivité (Responsive Design) */
@media  (max-width:1700px) {
    .localisation {
        gap: 6vh;
    }

    .location{
        gap: 6vh;
    }

    .map, .contact, .power{
        gap: 1.5vw;
    }

    .send_message {
        gap: 6vh;
    }

    .input {
        width: 35vw;
        height: 6.5vh;
    }

    .contact_form_input {
        width: 35vw;
        height: 14vh;
    }

    .submit {
        width: 35%;
        height: 6.5vh;
    }
}

@media (max-width:1300px) {
    .localisation {
        gap: 6vh;
    }

    .location{
        gap: 6vh;
    }

    .map, .contact, .power{
        gap: 1.5vw;
    }

    .send_message {
        gap: 6vh;
    }

    .input {
        width: 39vw;
        height: 6.5vh;
    }

    .contact_form_input {
        width: 39vw;
        height: 15vh;
    }

    .submit {
        width: 35%;
        height: 6.5vh;
    }
}

@media  (max-width: 1500px) {
    #contact_section {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        gap: 15vh;
    }

    .input {
        width: 55vw;
        height: 6.5vh;
    }

    .contact_form_input {
        width: 55vw;
        height: 15vh;
    }

    .submit {
        width: 35%;
        height: 6.5vh;
    }
}

/*------------*/
@media (max-width: 1024px) {
    .send_message{
        gap: 9vh;
    }

    .submit{
        width: 40%;
        height: 8vh;
        font-size: 20px;
    }
}

/*------------*/
@media (max-width: 768px) {
    #contact_section {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        gap: 15vh;
    }

    .localisation {
        display: flex;
        flex-direction: column;
        gap: 5vh;
        padding: 1vh 2vw;
    }

    .location {
        gap: 3vh;
    }

    .map,
    .contact,
    .power {
        gap: 4vw;
    }

    .power {
        line-height: 1.8;
    }

    .get_in_touch {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .send_message {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .input,
    .contact_form_input {
        width: 100%;
    }

    .submit {
        width: 50%;
        height: 6vh;
        margin: 4vh 0;
        font-size: 20px;
    }

    .name label {
        font-size: 22px;
    }
}

/*------------*/
@media (max-width: 480px) {}

/*------------*/
@media (max-width: 380px) {
    #contact_section {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .map {
        font-size: 13px;
    }

    .power {
        font-size: 13px;
    }

    .name label {
        font-size: 17px;
    }
}

.status-message {
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    animation: fadeInOut 5s ease forwards;
  }
  
  .status-message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .status-message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }
  
  @keyframes fadeInOut {
    0% { opacity: 0; transform: translateY(-10px); }
    10% { opacity: 1; transform: translateY(0); }
    90% { opacity: 1; }
    100% { opacity: 0; transform: translateY(-10px); }
  }
  