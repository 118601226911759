* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.multigraine_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7vw;
}

.multigraine_description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    gap: 4vh;
}

.multigraine_title_name {
    width: 60%;
    border-radius: 30px;
    padding: 1.5vh 0;
    gap: 0.5vh;
}

.multigrain {
    color: black;
    font-size: 58px;
    background-color: white;
    padding: 1vh 0;
    width: 80%;
}

.cracker {
    color: black;
    font-size: 38px;
    font-weight: 400;
    background-color: white;
    padding: 1vh 0;
    width: 45%;
}

.samp_multigraine{
    color: #ffaf01;
}

.samp_no_color{
    font-weight: 900;
}

.samp_multigraine_bold{
    color: #ffaf01;
    font-weight: 900;
}

.crackers_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4vw;
}

.crackers_green {
    background-color: transparent;
    height: 60vh;
}

.crackers_red {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: 60vh;
}

.crackers_blue {
    background-color: transparent;
    border-radius: 4px;
    height: 60vh;
}

.title_crackers_green,
.title_crackers_red,
.title_crackers_blue {
    display: flex;
    justify-content: center;
    align-items: center;
}

.crackers_green_div,
.crackers_red_div,
.crackers_blue_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
}

@media (max-width: 1690px) {
    .crackers_green,
    .crackers_red,
    .crackers_blue {
        height: 45vh;
    }
}

@media (max-width: 1600px) {
    .multigraine_container {
        display: flex;
        flex-direction: column;
        gap: 9vh;
        padding-bottom: 6vh;
    }

    .multigraine_description{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75%;
    }

    .multigraine_title_name{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .multigrain {
        background-color: white;
        border-radius: 9px;
        font-size: 50px;
        padding: 1vh 2vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cracker {
        padding: 1vh 2vw;
        background-color: white;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;    
    }

    .containt{
        line-height: 2.2;
    }
}

@media (max-width: 1300px) {
    .multigraine_title_name {
        display: flex;
        flex-direction: column;
        gap: 2vh;
        width: 100%;
    }

    .multigrain {
        background-color: white;
        border-radius: 9px;
        padding: 1vh 0;
    }

    .cracker {
        background-color: white;
        padding: 0.7vh 0;
        border-radius: 9px;
    }
}

/*------------*/
@media (max-width: 1024px) {
    .multigrain {
        font-size: 50px;
    }

    .cracker{
        font-size: 35px;
    }

    .containt {
        font-size: 15px;
        line-height: 2;
    }
}

/*------------*/
@media (max-width: 768px) {

    .multigraine_description {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .multigraine_title_name {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .multigrain {
        background-color: white;
        border-radius: 9px;
        font-size: 50px;
        padding: 1vh 2vw;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cracker {
        padding: 1vh 2vw;
        background-color: white;
        font-size: 30px;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;    
    }

    .containt {
        font-size: 20px;
        padding: 0vh 6vw;
        line-height: 2;
        letter-spacing: 0.4px;
    }

    .crackers_container {
        gap: 9vw;
    }

    .crackers_green,
    .crackers_red,
    .crackers_blue {
        height: 33vh;
    }
}

@media (max-width:594px) {}

/*------------*/
@media (max-width: 480px) {

    .crackers_green,
    .crackers_red,
    .crackers_blue {
        height: 24vh;
    }
}

/*------------*/
@media (max-width: 380px) {
    .multigrain {
        font-size: 40px;
    }

    .cracker {
        font-size: 25px;
    }

    .containt {
        font-size: 16.5px;
        line-height: 2;
        letter-spacing: 0.6px;
    }
}