/* Conteneur principal du carrousel */
.carousel-container {
    text-align: center;
    padding: 50px 20px;
}

/* Titre */
.carousel-title {
    font-size: 2rem;
    font-weight: bold;
    color: #ffaf01;
    margin-bottom: 20px;
}

/* Carrousel Swiper */
.company-swiper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    width: 50vw;
    margin: auto;
    padding-bottom: 50px;
}

.our_story_hr_company {
    border: none;
    width: 5vw;
    height: 1vh;
    background-color: #ffaf01;
    border-radius: 9px;
}

.company-swiper .swiper-slide {
    transition: transform 0.8s ease-in-out;
    /* Rend la transition plus fluide */
}

/* Images */
.carousel-image {
    width: 100%;
    height: 150px;
    object-fit: contain;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.carousel-image:hover {
    transform: scale(1.05);
    /* Effet léger de zoom au survol */
}


/* Pagination - Ajout d'un espace sous le carrousel */
.swiper-pagination {
    position: relative !important;
    /* Fixe la pagination sous le carrousel */
    margin-top: 20px !important;
    /* Ajoute de l’espace entre le carousel et la pagination */
}

/* Espacement entre les bullets */
.swiper-pagination-bullet {
    margin: 1vh 8vh;
    width: 1.4vw;
    border-radius: 10px;
    background: #ffaf01 !important;
}

@media (max-width:1024px) {
    .carousel-title{
        font-size: 20px;
    }

    .carousel-image{
        height: 18vh;
    }
}
@media (max-width:600px) {
    .our_story_hr_company{
        height: 0.7vh;
        width: 8vw;
    }
}